import media1 from './JamesJanice/IMG_0946.jpg';
import media2 from './JamesJanice/IMG_1122.jpg';
import media3 from './JamesJanice/IMG_1157.jpg';
import media4 from './JamesJanice/IMG_0950.jpg';
import media5 from './JamesJanice/IMG_1112.jpg';
import media6 from './JamesJanice/IMG_1114.jpg';
import media7 from './JamesJanice/IMG_1195.jpg';
import media8 from './JamesJanice/IMG_1151.jpg';
import media9 from './JamesJanice/IMG_0939.jpg';
import media10 from './JamesJanice/IMG_1149.jpg';
import media11 from './JamesJanice/IMG_0952.jpg';
import media12 from './JamesJanice/IMG_1154.jpg';
import media13 from './JamesJanice/IMG_1097.jpg';
import media14 from './JamesJanice/IMG_1122-2.jpg';

export const media = [
    media1,
    media2,
    media3,
    media4,
    media5,
    media6,
    media7,
    media8,
    media9,
    media10,
    media11,
    media12,
    media13,
    media14
];

export const size = [
    "250",
    "250",
    "250",
    "250",
    "560",
    "250",
    "250",
    "250",
    "250",
    "250",
    "250",
    "250",
    "560",
    "250"
];

export const mediaByIndex = index => media[index % media.length];
export const sizeByIndex = index => size[index % size.length];
