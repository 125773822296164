import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Navigator from './nav-bar';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Navigator />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
