import React from "react";
import "./css/collections.css";
import { mediaByIndex, sizeByIndex} from "./collections/AldoEmma";

function CollectionPage1(params) {
  const slides = Array.from(Array(params.size).keys());
  var width = window.innerWidth;
  window.addEventListener('resize', 
    function() {
      if (window.location.href.includes("AldoEmma") === false) {
        window.location.reload();
      }
      if(window.innerWidth !== width){
        closeImage();
      } 
    }
  );

  function openImage(source, size) {
    document.getElementById("display-image").style.display = "flex";
    document.getElementById("display-image").style.animation = "fadeIn 0.5s";
    document.getElementById("selected-image").src = source;
    if (window.innerWidth < 1050) {
      document.getElementById("selected-image").style.width = (parseInt(size) * (window.screen.width/1100)).toString() + "px";
    }
    else{
      document.getElementById("selected-image").style.width = (parseInt(size) * (window.screen.width/800)).toString() + "px";
    }
    
    document.querySelector('html').style.overflow = "hidden";
    document.querySelector('#root').style.overflow = "hidden";
  }

  function closeImage() {
    document.getElementById("display-image").style.animation = "fadeOut 0.5s";
    setTimeout(function(){
      document.getElementById("display-image").style.display = "none";
    },500);
    document.querySelector('html').style.overflow = "scroll";
    document.querySelector('#root').style.overflow = "scroll";
  };
  
    return (
      <div id="collection-box">
        <div className="largeSizes">
          <div id="large-size-box">
        {slides.map((index) => (
          <img key={index} onClick={() => {openImage(mediaByIndex(index), sizeByIndex(index))}} id="image" 
            src={mediaByIndex(index)} alt="Jacqueline's Photography"
            width={sizeByIndex(index)}
          />
        ))}
        </div>
        <div id="display-image" onClick={closeImage}>
          <div id="image-box">
              <img
                alt="Jacqueline's Photography" 
                id="selected-image">
              </img>
          </div>
        </div>
      </div>
      <div className="smallSizes">
        {slides.map((index) => (
          <img key={index} id="image" 
            src={mediaByIndex(index)} alt="Jacqueline's Photography"
            width={"90%"}
            height= {"100%"}
          />
        ))}
      </div>
    </div>
  );
}

export default CollectionPage1;

