
import media1 from './AldoEmma/IMG_8875.jpg';
import media2 from './AldoEmma/IMG_8934.jpg';
import media3 from './AldoEmma/IMG_8883.jpg';
import media4 from './AldoEmma/IMG_8003.jpg';
import media5 from './AldoEmma/IMG_8760.jpg';
import media7 from './AldoEmma/IMG_8888.jpg';
import media6 from './AldoEmma/IMG_9507.jpg';
import media8 from './AldoEmma/IMG_8979.jpg';
import media9 from './AldoEmma/IMG_8859-2.jpg';
import media15 from './AldoEmma/IMG_8822.jpg';
import media11 from './AldoEmma/IMG_8975.jpg';
import media12 from './AldoEmma/IMG_8898.jpg';
import media13 from './AldoEmma/IMG_8024.jpg';
import media14 from './AldoEmma/IMG_8045.jpg';
import media19 from './AldoEmma/IMG_8755.jpg';
import media16 from './AldoEmma/IMG_9401.jpg';
import media17 from './AldoEmma/IMG_8908.jpg';
import media18 from './AldoEmma/IMG_9014-2.jpg';
import media10 from './AldoEmma/IMG_8751.jpg';
import media21 from './AldoEmma/IMG_9506.jpg';
import media20 from './AldoEmma/IMG_9391.jpg';
import media22 from './AldoEmma/IMG_8826.jpg';
export const media = [
    media1,
    media2,
    media3,
    media4,
    media5,
    media6,
    media7,
    media8,
    media9,
    media10,
    media11,
    media12,
    media13,
    media14,
    media15,
    media16,
    media17,
    media18,
    media19,
    media20,
    media21,
    media22
];

export const size = [
    "250",
    "560",
    "560",
    "250",
    "250",
    "250",
    "250",
    "560",
    "250",
    "250",
    "250",
    "250",
    "250",
    "560",
    "250",
    "560",
    "250",
    "250",
    "250",
    "250",
    "250",
    "250"
];

export const mediaByIndex = index => media[index % media.length];
export const sizeByIndex = index => size[index % size.length];
