
import media1 from './aj1.jpg';
import media2 from './armors1.jpg';
import media3 from './bernie1.jpg';
import media4 from './astrid1.jpg';
import media5 from './zara1.jpg';
import media6 from './aly1.jpg';
import media7 from './bernie2.jpg';
import media8 from './aj2.jpg';
import media9 from './zara2.jpg';
import media10 from './tyler1.jpg';
import media11 from './aly2.jpg';
import media12 from './aj3.jpg';
import media13 from './astrid2.jpg';
import media14 from './aj4.jpg';

export const media = [
    media1, 
    media2, 
    media3, 
    media4, 
    media5, 
    media6, 
    media7, 
    media8,
    media9,
    media10,
    media11, 
    media12, 
    media13, 
    media14
];

export const description = [
    "001 / ALY & AJ", 
    "002 / ARMORS", 
    "003 / BERNIE SANDERS", 
    "004 / ASTRID S", 
    "005 / ZARA LARSSON", 
    "006 / ALY & AJ", 
    "007 / BERNIE SANDERS", 
    "008 / ALY & AJ",
    "009 / ZARA LARSSON",
    "0010 / TWENTY ONE PILOTS",
    "0011 / ALY & AJ", 
    "0012 / ALY & AJ", 
    "0013 / ASTRID S", 
    "0014 / ALY & AJ"
];

export const mediaByIndex = index => media[index % media.length];
export const descriptionByIndex = index => description[index % description.length];
