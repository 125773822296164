import './css/App.css';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import "./css/contact.css";
import { init, sendForm } from 'emailjs-com';
init("user_uFxU4luDAGoylgiVtktkW");
//412 Error Troubleshoot -- change email service, service ID
function ContactPage() {
    const {register, handleSubmit, watch, formState: { errors },} = useForm();
    const [contactNumber, setContactNumber] = useState("000000");
    var [submitText, setSubmitText] = useState("SUBMIT");

    const onSubmit = () => {
        setSubmitText(submitText="SUBMITTING...");
        const form = document.querySelector('#form-info');
        generateContactNumber();
        sendForm('service_qysegn3', 'template_79m27pu', '#form-info').then(function() {
            document.querySelector("#status-message1").innerHTML = "YOUR MESSAGE HAS BEEN SENT";
            document.querySelector("#status-message2").innerHTML = "Thank you for reaching out, I will be in contact with you shortly.";
            form.reset();
            displayMessage();
        }, function() {
            document.querySelector("#status-message1").innerHTML =  "AN ERROR HAS OCCURED";
            document.querySelector("#status-message2").innerHTML = "Failed to send message. Please try again later.";
            displayMessage();
        });
    }

    const message = watch('message') || "";
    const messageCharsLeft = 320 - message.length; 

    const generateContactNumber = () => {
        const numStr = "000000" + (Math.random() * 1000000 | 0);
        setContactNumber(numStr.substring(numStr.length - 6));  
    }

    function displayMessage() {
        const x = document.querySelector('.status-box');
        if (x.style.display === "none") {
            x.style.display = "flex";
            document.querySelector('html').style.overflow = "hidden";
            document.querySelector('#root').style.overflow = "hidden";
        } else {
            x.style.display = "none";
            document.querySelector('html').style.overflow = "scroll";
            document.querySelector('#root').style.overflow = "scroll";
            setSubmitText(submitText="SUBMIT");
            document.getElementById("input-4").focus();
            document.getElementById("input-3").focus();
            document.getElementById("input-2").focus();
            document.getElementById("input-1").focus();
            document.getElementById("input-1").blur();
        }
    }

    function scrollToTop() {
        window.scrollTo(0,0);
    }
    
  return (
        <div className="contact-contents">
            <div className="status-box" style={{display: `none`}}>
                <div className="status-message-box" >
                    <div id="status-message1"></div>
                    <div id="status-message2"></div>
                    <div id="done-button" onClick={displayMessage} type="continue">DONE</div> 
                </div>
            </div>
            <div className="name-info">
                <div id="name-info-box">
                    <div id="info1">JACQUELINE RHODA</div>
                    <div id="info2">JACQUELINERHODA@GMAIL.COM</div>
                    <div id="info2">JACQUELINERHODA.COM</div>
                </div>
            </div>
            <div className="vertical-line-box">
                <div id="vertical-line"></div>
            </div>
            <form id="form-info" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <input type='hidden' name='contact_number' value={contactNumber} />
                <div className="text-info-box">
                    <div className="input-title-box">
                        <div id="input-title">FULL NAME</div>
                        {errors.fullName && <div id="required-error">* Required</div>}
                    </div>
                    <input type="text" id="input-1" autoComplete="off" className="input-box1" {...register('fullName', { required: true })}/> 
                </div>
                <div className="text-info-box">
                    <div className="input-title-box">
                        <div id="input-title">EMAIL</div>
                        {errors.email && <div id="required-error">* Required</div>}
                    </div>
                    <input id="input-2" autoComplete="off" type="email" className="input-box1" {...register('email', { required: true })} />
                </div>
                <div className="text-info-box">
                    <div className="input-title-box">
                        <div id="input-title">SUBJECT</div>
                        {errors.subject && <div id="required-error"> * Required</div>}
                    </div>
                    <input type="text" id="input-3" autoComplete="off" className="input-box1" {...register('subject', { required: true })} />
                </div>
                <div className="text-info-box">
                    <div className="input-title-box">
                        <div id="input-title">MESSAGE</div>
                        {errors.message && <div id="required-error">* Required</div>}
                    </div>
                    <textarea type="text" id="input-4" autoComplete="off" maxLength='320' className="input-box2" {...register('message', { required: true })} />
                    <div style={{color: messageCharsLeft === 0 ? "rgb(205, 84, 84)" : "rgb(125, 123, 121)"}} className='message-chars-left'>{messageCharsLeft}</div>
                </div>
                <input id="submit-button" onClick={scrollToTop} type="submit" value={submitText} /> 
            </form>
        </div>
  );
}

export default ContactPage;

