import "./css/film.css";
import React, { useState, useRef} from "react";
import ReactPlayer from 'react-player'

function FilmPage() {
  var [play, setVideo] = useState(false);
  var [mute, setMute] = useState(true);
  var [first, setFirst] = useState(false);
  var time = useRef();

   if(first === false) {
    time.current = setTimeout(function () {
      if (document.querySelector("#unmute-box")) {
        document.querySelector("#unmute-box").style.display = "none"
        setVideo(play = true);
        setFirst(first = true);
      }
    }, 6000);
  }

  return (
    <div id="film-container" >
      <div id="film-box" >
      <div id="film-count">01/1</div>
        <div id="film-top">
          <div id="film-title">VIEWING: FAIR ON FILM '19</div>
        </div>
        <div className="video-box">
          <div id="white-crop">
            <div id="white-bar"></div>
            <div id="white-bar"></div>
          </div>
          <div id="unmute-box" onClick={() => {
              if (first === false) {
                setMute(mute = false);
                setVideo(play = true);
                setFirst(first = true);
                clearTimeout(time.current);
                document.querySelector("#unmute-box").style.display = "none";
              }
            }}>
            <div id="unmute-text">tap to unmute</div>
          </div>
          <div onClick={() => {setMute(!mute);}} id="mute-click-box"></div>
          <ReactPlayer id="video" volume={0.15} muted={mute} playing={play} loop={true} url='https://jacquelinewebsitebucket.s3.amazonaws.com/fairFilm.mp4' />
        </div>
        <div id="button-row">
          <div  onClick={() => {if(first === true){setVideo(play = true)}}} id="playPause"> play /</div>
          <div  onClick={() => {if(first === true){setVideo(play = false)}}} id="playPause"> &nbsp;pause</div>
        </div>
          </div>
    </div>
  );
}

export default FilmPage;

