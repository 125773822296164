import React from "react";
export const PrevButton = ({ enabled, onClick }) => (
  <div id="textButton" onClick={onClick} disabled={!enabled}>prev</div>
);

export const NextButton = ({ enabled, onClick }) => (
  <div id="textButton" onClick={onClick} disabled={!enabled}>/next</div>
);
export const SideButtonLeft = ({ enabled, onClick }) => (
  <div id="prevSlideButton" onClick={onClick} disabled={!enabled}></div>
);
export const SideButtonRight = ({ enabled, onClick }) => (
    <div id="nextSlideButton" onClick={onClick} disabled={!enabled}></div>
);