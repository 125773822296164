import React from "react";
import EmblaCarousel from "./EmblaCarousel.js";
import "./css/embla.css";
import { mediaByIndex, descriptionByIndex } from "./media/media";

const SLIDE_COUNT = 14;
const slides = Array.from(Array(SLIDE_COUNT).keys());

function Home() {
  var width = window.innerWidth;
  window.addEventListener('resize', 
    function() {
      if(window.location.href.includes("AldoEmma") === false && window.location.href.includes("JamesJanice") === false &&
       window.location.href.includes("film") === false && window.location.href.includes("film") === false
       && window.innerWidth !== width && ((window.innerWidth > 850 && window.innerHeight > 400) || window.innerWidth > 795)){
        window.location.reload(); 
      } 
    }
  );

  return (
    <div id="center">
        <EmblaCarousel className="slides" slides={slides} autoplay delayLength={7000}/>
        <div className="photo-column">
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(0)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(0)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(1)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(1)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(2)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(2)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(3)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(3)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(4)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(4)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(5)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(5)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(6)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(6)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(7)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(7)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(8)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(8)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(9)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(9)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(10)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(10)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(11)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(11)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(12)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(12)}</div>
          </div>
          <div className="photo-box">
            <img id="photo" src={mediaByIndex(13)} alt="Jacqueline's Photography" />
            <div id="picture">{descriptionByIndex(13)}</div>
          </div>
        </div>
        {/* <div className="rights-box">
          © Jacqueline Rhoda. All rights reserved.
        </div> */}
    </div>
  );
}

export default Home;

