import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import Home from "./home-page";
import ContactPage from "./contact-page";
import CollectionsPage1 from "./AldoEmma-page";
import CollectionsPage2 from "./JamesJanice-page";

import FilmPage from "./film-page";

import "./css/App.css";

export default function Navigator() {
  const [showDropDown, setShowDropDown] = useState("none");
  const [dropDownBorder, setDropDownBorder] = useState("0 0 0 0.4px white");
  const [collectionTab, setCollectionTab] = useState("rgb(67, 66, 64, .5)");

  const menuClick = () => {
    if (showDropDown === "block") {
      setShowDropDown("none");
      setDropDownBorder("0 0 0 0.4px white");
      setCollectionTab("rgb(67, 66, 64, .5)");
    } else {
      setShowDropDown("block");
      setDropDownBorder("0 0 0 0.4px black");
      setCollectionTab("black");
    }
  };

  const closeMenu = () => {
    setShowDropDown("none");
    setDropDownBorder("0 0 0 0.4px white");
    setCollectionTab("rgb(67, 66, 64, .5)");
  };

  return (
    <Router>
      <header className="App-header">
        <div className="button-nav">
          <NavLink
            id="portfolio-tab"
            activeStyle={{ color: "black" }}
            className="btn-nav"
            exact={true}
            to="/"
          >
            PORTFOLIO
          </NavLink>
          <NavLink
            id="contact-tab"
            activeStyle={{ color: "black" }}
            className="btn-nav"
            exact={true}
            to="/contact"
          >
            CONTACT
          </NavLink>
          <div
            onClick={menuClick}
            onMouseLeave={closeMenu}
            id="menu"
            activeStyle={{ color: "black" }}
            style={{ boxShadow: `${dropDownBorder}` }}
          >
            <div id="collection-tab" style={{ color: `${collectionTab}` }}>
              COLLECTIONS
            </div>
            <div id="drop-down" style={{ display: `${showDropDown}` }}>
              <div className="line-box">
                <div id="line"></div>
              </div>
              <NavLink
                className="menu-options"
                style={{ paddingTop: `1px` }}
                exact={true}
                to="/collections/AldoEmma"
              >
                ALDO AND EMMA
              </NavLink>
              <NavLink
                className="menu-options"
                style={{ paddingBottom: `12px` }}
                exact={true}
                to="/collections/JamesJanice"
              >
                JAMES AND JANICE
              </NavLink>
            </div>
          </div>
          {/* <NavLink
            id="film-tab"
            activeStyle={{ color: "black" }}
            className="btn-nav"
            exact={true}
            to="/film"
          >
            SUPER 8 FILM
          </NavLink> */}
        </div>
        <div id="name-container">
          <NavLink id="name" to="/">
            JACQUELINE RHODA
          </NavLink>
          <NavLink id="email" to="/">
            JACQUELINERHODA@GMAIL.COM
          </NavLink>
        </div>
      </header>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/contact">
          <ContactPage />
        </Route>
        <Route path="/collections/AldoEmma">
          <CollectionsPage1 size={22} />
        </Route>
        <Route path="/collections/JamesJanice">
          <CollectionsPage2 size={14} />
        </Route>
        <Route path="/film">
          <FilmPage />
        </Route>
      </Switch>
    </Router>
  );
}
