import React, { useState, useRef, useCallback } from "react";
import { PrevButton, NextButton, SideButtonLeft, SideButtonRight } from "./EmblaCarouselButtons";
import useInterval from "./useInterval";
import { useEmblaCarousel } from "embla-carousel/react";
import { mediaByIndex, descriptionByIndex} from "./media/media";
import "./css/embla.css";

const EmblaCarousel = ({ slides, autoplay, delayLength }) => { 
  var pause = false;
  window.addEventListener('focus', function() {
    pause = false;
    setDelay(7000);
  },false);

  window.addEventListener('blur', function() {
    pause = true;
  },false);

  var slide = useRef(0);

  function fadeSlide(index) {
    index = index += 1
    var previous;
    var next;
    if (index === 1) {
      previous = 14
    } else{
      previous = index - 1
    }
    if (index === 14) {
      next = 1
    } else{
      next = index + 1
    }

    document.querySelector("#s" + slide.current.toString()).innerHTML = descriptionByIndex(slide.current);
    if (slide.current === 7 || slide.current === 13) {
      document.querySelector(".description-box").style.width = "350px"  
    } 
    else{
      document.querySelector(".description-box").style.width = "795px"
    }
    document.querySelector(`.embla__slide:nth-child(${previous.toString()}) .embla__slide__inner .embla__slide__img`).style.filter= "blur(5px)";
    document.querySelector(`.embla__slide:nth-child(${index}) .embla__slide__inner .embla__slide__img`).style.filter= "blur(0px)";
    document.querySelector(`.embla__slide:nth-child(${next.toString()}) .embla__slide__inner .embla__slide__img`).style.filter= "blur(5px)";

  }

  const [viewportRef, embla] = useEmblaCarousel({
    loop: true,
    draggable: false,
    containScroll: "trimSnaps",
    speed: 8
  });

  const [prevBtnEnabled] = useState(false);
  const [nextBtnEnabled] = useState(false);
  
  const scrollNext = useCallback(() => {
    if(pause) return;
    var previous;
    if (!embla) return;
    embla.scrollNext();
    slide.current += 1;
    previous = slide.current - 1
    if (slide.current === 14) {
      slide.current = 0;
    } else if (previous === -1) {
        previous = 13;
    }

    document.querySelector("#s" + previous.toString()).style.display = "none";
    document.querySelector("#s" + slide.current.toString()).style.display = "flex";

    fadeSlide(slide.current);
  }, [embla]);

  const scrollPrev = useCallback(() => {
    var next;
    if (!embla) return;

    slide.current -= 1;
    next = slide.current + 1;
    embla.scrollPrev();
    if (slide.current === -1) {
      slide.current = 13;
    } else if (next === 14){
        next = 0;
    }

    document.querySelector("#s" + next.toString()).style.display = "none";
    document.querySelector("#s" + slide.current.toString()).style.display = "flex";
    fadeSlide(slide.current);
  }, [embla]);

  const [delay, setDelay] = useState(delayLength);
  const isRunning = useState(autoplay);

  useInterval(
    () => {
        scrollNext();
    },
    isRunning ? delay : null
  );

  function setAutoPlay() {
    setDelay(7000);
  }
  function handleDelayChange(e) {
    setDelay(e);
    setTimeout(function(){ setAutoPlay();}, e);
  }

return (
    <div className="embla">
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          {slides.map((index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__inner">
                <img
                  className="embla__slide__img"
                  src={mediaByIndex(index)}
                  alt="Jacqueline's Photography"/>
              </div>
            </div>
          ))}
        </div>
        <div className="shadow1"></div>
        <div className="shadow2"></div>
        <div className="white-box">
          <div className="white-cut1"></div>
          <div className="white-cut2"></div>
        </div>
        <div style={{width: `100%`, display: `flex`, justifyContent: `center`}}>
          <div id="test"></div>
          <div style={{width: (slide.current === 7 || slide.current === 13) ? `350px` : `795px`}} className="description-box">
            <div className="captionSlide" id="s0">{descriptionByIndex(slide.current)}</div>
            <div className="captionSlide" id="s1"></div>
            <div className="captionSlide" id="s2"></div>
            <div className="captionSlide" id="s3"></div>
            <div className="captionSlide" id="s4"></div>
            <div className="captionSlide" id="s5"></div>
            <div className="captionSlide" id="s6"></div>
            <div className="captionSlide" id="s7"></div>
            <div className="captionSlide" id="s8"></div>
            <div className="captionSlide" id="s9"></div>
            <div className="captionSlide" id="s10"></div>
            <div className="captionSlide" id="s11"></div>
            <div className="captionSlide" id="s12"></div>
            <div className="captionSlide" id="s13"></div>
          </div>
        </div>
      </div>
      <SideButtonLeft onClick={() => {scrollPrev(); handleDelayChange(13000);}} enabled={prevBtnEnabled} />
        <SideButtonRight onClick={() => {scrollNext(); handleDelayChange(13000);}}enabled={nextBtnEnabled} />
     <div className="buttonRow">
        <PrevButton onClick={() => {scrollPrev(); handleDelayChange(13000);}} enabled={prevBtnEnabled} />
        <NextButton onClick={() => {scrollNext(); handleDelayChange(13000);}} enabled={nextBtnEnabled} />
      </div>
    </div>
  );
};

export default EmblaCarousel;
